import normalize from "normalize.css" // eslint-disable-line no-unused-vars
import style from "./index.scss" // eslint-disable-line no-unused-vars

import { archimedes } from "./spirals"
import { curves } from "./visualizations"

window.draw = function() {
  const container = document.querySelector(".canvas svg")
  container.innerHTML = ""
  const points = archimedes((3 * 4 * 2) + 1, 3, 1)
  curves(container, points)
}

document.onreadystatechange = function() {
  if (document.readyState === "complete") {
    // window.draw()
  }
}
