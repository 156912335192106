const SVGNS = "http://www.w3.org/2000/svg"

export function createDot(point) {
  const dot = document.createElementNS(SVGNS, "circle")
  dot.setAttribute("cx", point.x)
  dot.setAttribute("cy", point.y)
  dot.setAttribute("r", 0.01)
  return dot
}

export function createPath() {
  const path = document.createElementNS(SVGNS, "path")
  path.setAttribute("stroke", "white")
  path.setAttribute("stroke-width", 0.01)
  return path
}

export function curves(container, points) {
  const path = createPath()
  const initial = points[0]
  const remainder = points.slice(1).map((point, index, array) => {
    const pointDot = createDot(point)
    pointDot.setAttribute("r", 0.02)
    pointDot.setAttribute("fill", "red")
    container.appendChild(pointDot)

    const last = array[index]

    const c1 = Object.assign({}, last)
    c1.x = (point.x + last.x) / 2
    c1.y = (point.x + last.y) / 2
    const c1Dot = createDot(c1)
    c1Dot.setAttribute("fill", "green")
    container.appendChild(c1Dot)

    const c2 = Object.assign({}, point)
    const c2Dot = createDot(c2)
    c2Dot.setAttribute("fill", "blue")
    container.appendChild(c2Dot)

    return `C ${c1.x} ${c1.y} ${c2.x} ${c2.y} ${point.x} ${point.y}`
  })
  const description = `m ${initial.x} ${initial.y} ` + remainder.join(" ")
  path.setAttribute("d", description)
  container.prepend(path)
}

export function dots(container, points) {
  points.forEach((point) => {
    const dot = createDot(point)
    dot.setAttribute("fill", "white")
    container.appendChild(dot)
  })
}

export function straightLines(container, points) {
  const path = createPath()
  const initial = points[0]
  const remainder = points.slice(1).map((point) => {
    return `L ${point.x} ${point.y}`
  })
  const description = `m ${initial.x} ${initial.y} ` + remainder.join(" ")
  path.setAttribute("d", description)
  container.appendChild(path)
}
