import { PI2, polarToScreen } from "./math"

export function archimedes(steps, turns, totalDistance, a = 0) {
  const stepsPerTurn = (steps - 1) / (turns || 1)
  const b = (totalDistance / (turns || 1)) / PI2

  const points = []
  for (let index = 0; index < steps; index++) {
    const theta = index / stepsPerTurn * PI2
    const radius = a + (b * theta)
    const point = polarToScreen(radius, theta)
    points.push(point)
  }
  return points
}
