export const PI2 = 2 * Math.PI

export function polarToCartesian(radius, angle) {
  const x = radius * Math.cos(angle)
  const y = radius * Math.sin(angle)
  return { x, y }
}

export function polarToScreen(radius, angle) {
  const x = radius * Math.cos(angle)
  const y = radius * -Math.sin(angle)
  return { x, y }
}
